import React from 'react';
import { Link } from 'react-router-dom';

import notfound404 from '../assets/404.jpg';
import '../styles/Error404.css';

const Error404 = () => {
	return (
		<div className="error404Container">
			<img className="" src={notfound404} alt="404" />
			<p>
				I'm{' '}
				<span data-toggle="tooltip" data-placement="top" title="aren't we all">
					fragile
				</span>{' '}
				and it seems that I broke. Go to{' '}
				<Link to="/home" className="animated-link">
					home page
				</Link>
			</p>
		</div>
	);
};

export default Error404;
