import React from 'react';
import '../styles/24_contactMe.css';
export const ContactForm = () => {
	return (
		<div className="container contactMe-Container" data-aos="fade-up">
			<p className="contactMeHeader">Get in Touch</p>
			<p className="contactMeDescription">
				Whether you have a question, a potential collaboration in mind, or just want to chat , you can reach out on my{' '}
				<a className="animated-link" href="mailto:ahmelsamahy@gmail.com" target="_blank" rel="noreferrer">
					personnel email
				</a>
				. I will try to get back to you as soon as possible
			</p>
		</div>
	);
};
