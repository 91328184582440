/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ProjectCard from './projectCard.jsx';
import ConfigCredentials from '../config.jsx';

let config = ConfigCredentials();
export function Top3Projects() {
	const [data, setData] = useState([]);
	useEffect(() => {
		fetch(`${config.backendURL}/get/top3projects/`)
			.then((response) => response.json())
			.then((data) => setData(data))
			.catch((error) => console.error('Error fetching data:', error));
	}, []);

	return (
		<div style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
			<div className="text-center" data-aos="fade-up">
				<p className="Top3ProjectsTextCenter">Featured projects</p>
				<Link to="/allProjects" className="animated-link viewAllProjects">
					See all the others
				</Link>
			</div>

			<div className="container Top3ProjectsContainer">
				<ProjectCard data={data} showImage />
			</div>
		</div>
	);
}
