import React from 'react';
import YoungMe from '../assets/myImages/YoungMe.webp';
import ScienceEvent from '../assets/myImages/ScienceEvent.webp';
import Traveling from '../assets/myImages/Traveling.webp';
import Airport from '../assets/myImages/Airport.webp';
function LifeStoryPersonnal() {
	return (
		<>
			<div className="life-story" data-aos="fade-up">
				<p className="firstSpark">
					{' '}
					<span className="numbering">1.</span>From spark
				</p>
				<div className="lifeStory-innerContent">
					<p className="lifeStory-content">From a tender age, my world was colored with curiosities. Whether it was the simple act of connecting batteries or the daring thought of exploring wall outlets, my inquisitiveness knew no bounds. Thankfully, with guidance and time, I channeled this energy into more constructive avenues.</p>
					<img className="YoungMe-image" src={YoungMe} title="I couldn't read then" alt="Reading a story to my little sister"></img>
				</div>
			</div>

			<div className="life-story" data-aos="fade-up">
				<p className="firstSpark">
					{' '}
					<span className="numbering">2.</span>Academic Evolution
				</p>
				<div className="lifeStory-innerContent">
					<img className="lifeStoryPersonnalImage" src={ScienceEvent} title="What a pose" alt="leaning on a wall in a science fair"></img>
					<div className="lifeStory-content">
						<p className="lifeStory-containerText">
							After a decade-long journey culminating in high school graduation, I was fortunate to secure a spot in the engineering program at{' '}
							<a href="https://www.deltauniv.edu.eg/" className="animated-link" target="_blank" rel="noreferrer">
								Delta University
							</a>
							. Here, I deepened my understanding of two subjects I've always been passionate about: physics and math. The intensity of the coursework was both a challenge and an enlightening experience.
							<br></br>
							<br></br>
						</p>
						<p className="lifeStory-containerText">Amid this demanding environment, I found a respite in a telecommunication specialization. One particularly memorable experience was a programming course where, in collaboration with a classmate, we developed a data management application for a library using C++. This project, though modest, became a pivotal moment, affirming my interest in programming and hinting at a potential future direction away from traditional engineering.</p>
					</div>
				</div>
			</div>

			<div className="life-story" data-aos="fade-up">
				<p className="firstSpark">
					{' '}
					<span className="numbering">3.</span>Global Exposure
				</p>
				<div className="lifeStory-innerContent">
					<img className="lifeStoryPersonnalImage " src={Traveling} title="g=10m/s^2" alt="Jumping with DoBull around my head "></img>
					<div className="lifeStory-content">
						<p className="lifeStory-containerText">Though I excelled academically, ranking #5 in my class, and formed lasting relationships during my time at Delta University, I always felt a yearning for broader horizons and new challenges.</p>

						<p className="lifeStory-containerText">
							This sentiment was realized on July 2nd, 2020, when I received an acceptance notification from the prestigious{' '}
							<a className="animated-link" href="https://stipendiumhungaricum.hu/" target="_blank" rel="noreferrer">
								Stipendium Hungaricum
							</a>{' '}
							scholarship program to join Eszterházy Károly Catholic University{' '}
							<a className="animated-link" href="https://uni-eszterhazy.hu/" target="_blank" rel="noreferrer">
								(EKKE)
							</a>
							. This opportunity marked a significant turning point in my academic and personal journey.{' '}
						</p>
						<p className="lifeStory-containerText">Stepping into this new chapter at EKKE was both enlightening and challenging. Faced with an unfamiliar culture, I quickly adapted, emphasizing mutual respect and understanding. This experience reinforced the importance of adaptability and cultural sensitivity in any global setting.</p>
					</div>
				</div>
			</div>

			<div className="life-story" data-aos="fade-up">
				<p className="firstSpark">
					{' '}
					<span className="numbering">4.</span>Cultural Engagement
				</p>
				<div className="lifeStory-innerContent">
					<img className="lifeStoryPersonnalImage" src={Airport} title="did they check in my luggage?" alt="looking out of Ferenc airport window in Budapest"></img>
					<div className="lifeStory-content">
						<p className="lifeStory-containerText">Daily interactions with diverse cultures broadened my perspective, underscoring the vastness and richness of the world around me. I firmly believe that every individual carries a unique story, and as a naturally sociable person, I sought to understand these narratives.</p>

						<p className="lifeStory-containerText">Proactively engaging with my peers, I endeavored to learn about their backgrounds, embracing the differences and finding common ground. These interactions were mutual; many were equally curious about my origins. Such exchanges enriched my understanding of the world and contributed significantly to my personal and social development.</p>
					</div>
				</div>
			</div>
		</>
	);
}
export default LifeStoryPersonnal;
