export function Footer() {
  return (
    <>
      <footer>
        <div>
          <hr className="footer-break"></hr>
          <p className="footerCredits">
            Designed and developed with{" "}
            <span style={{ color:  "#7F7F7F" }}>&#9829;</span> By Ahmed Mahfouz
          </p>
        </div>
        <ul className="socialMediaConnection">
          <li>
            <a href="https://twitter.com/AhElsamahy">
              <i className="fa fa-twitter"></i>
            </a>
          </li>
          <li>
            <a href="https://github.com/Ahelsamahy">
              <i className="fa fa-github"></i>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/ahmed-mahfouz-8005a017a/">
              <i className="fa fa-linkedin-square"></i>
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}
