import React from 'react';
import { Link } from 'react-router-dom';
import { storyCoverPath } from '../functions.jsx';
import '../styles/cards.css';

function ProjectCard({ data, showImage }) {
  return (
    <>
      {data.map((project, i) => (
        <React.Fragment key={i}>
          <div key={i} className="col" data-aos="fade-up">
            <div className="card cardScale">
              {showImage ? (
                <div className="image">
                  {project.PostImage.substr(project.PostImage.indexOf('.')) === '.webm' ? (
                    <video preload="auto" autoPlay muted loop="True">
                      <source src={storyCoverPath(project.PostImage)} type="video/webm" />
                    </video>
                  ) : (
                    <img src={storyCoverPath(project.PostImage)} alt="image for the project" />
                  )}
                </div>
              ) : (
                <></>
              )}
              <div className="card-inner">
                <div className="languageColor" style={{ backgroundColor: project.color }}></div>
                <div className="header">
                  <h1 className="PostTitle">{project.Title}</h1>
                  <p className="text-secondary"> {project.ProLanguage}</p>
                </div>
                <div className="content">
                  <div className="navigationButtons">
                    <p id="PostDescription"> {project.Description}</p>
                    <div id="ReadMore">
                      <ul className="projectsCardButton">
                        {project.Content && (
                          <li key={`content-${i}`}>
                            {project.Content.length > 2 && project.Content.length < 100 ? (
                              <Link to={project.Content} className="btn toStoryButton">
                                Story
                              </Link>
                            ) : (
                              <Link to={`/StoryProject/${project.ProjectID}`} className="btn toStoryButton">
                                Story
                              </Link>

                            )}
                          </li>
                        )}

                        {project.gitHubLink && (
                          <li key={`github-${i}`}>
                            <a href={project.gitHubLink} target="_blank" rel="noreferrer">
                              <i className="fa fa-github toGithubButton"></i>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ))}
    </>
  );
}
export default ProjectCard;
