import React, { useEffect } from 'react';
import '../styles/21_introSection.css';
import myImage from '../assets/myImages/1_me.webp';

const IntroSection = () => {
  /**
   * Function to simulate the typewriter effect
   * @param {string} text Test that will be simulated in a typewriting effect
   * @param {string} elementId Place where the effect be done
   */
  const typewriter = async (text, elementId) => {
    return new Promise((resolve) => {
      const type = (i) => {
        if (i < text.length) {
          document.querySelector(elementId).textContent += text.charAt(i);
          setTimeout(() => type(i + 1), 100);
        } else {
          resolve(); // Resolve the promise when typewriter effect is done
        }
      };
      type(0); // Start the typewriter effect
    });
  };

  useEffect(() => {
    //To make it run the function only when the page is loaded, not when I just click on the home button on header
    const startTypewriters = async () => {
      await new Promise((resolve) => setTimeout(resolve, 4000)); // Delay for the first text
      await typewriter('Hi, my name is', '#welcome-name');

      await new Promise((resolve) => setTimeout(resolve, 2500)); // Delay for the second text
      await typewriter('Ahmed Mahfouz.', '#All-name');
    };
    startTypewriters();
  }, []);

  return (
    <div className="introSection-AllAboutMe">
      <div id="introSection-AboutMe">
        <div className="introSectionContainerText">
          <p id="welcome-name"></p>
        </div>
        <div className="introSectionContainerText">
          <p id="All-name"></p>
        </div>
        <div className="introSectionContainerText">
          <p id="my-intro">I will take you on a tour of my life as a software developer</p>
        </div>

        <div className="scrollUp hide-on-phone" data-aos="fade-up" data-aos-duration="3000">
          <p>Scroll</p>
          <div className="arrows-container">
            <div className="arrow up arrow-one"></div>
            <div className="arrow up arrow-two"></div>
          </div>
        </div>
      </div>
      <div className="personnel-image">
        <img src={myImage} className="img-fluid intro-image" alt="Updated LinkedIn" />
      </div>
    </div>
  );
};

export default IntroSection;
