import React from 'react';

export function AllBlackIntro({ show }) {
	// for debugging
	// useEffect(() => {
	// 	let showIntro = show;
	// 	console.log(showIntro);
	// }, [show]);

	return (
		<div className={`allBlackIntro ${show ? 'show' : 'hide'}`}>
			<div className="Letter Ahmed animate__animated">A</div>
			<div className="Letter Mahfouz animate__animated">M</div>
		</div>
	);
}
