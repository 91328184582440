import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export const Header = () => {
  return (
    <>
      <header className="indexHeader">
        <ul>
          <li className="nav-item">
            <Link to="/home" className="nav-link">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <HashLink className="nav-link" to={'/home#aboutSection'}>
              About
            </HashLink>
          </li>
          <li className="nav-item">
            <Link to="/allProjects" className="nav-link">
              Projects
            </Link>
          </li>
          {/* <li className="nav-item"><a href="?p=" className="nav-link">Posts</a></li>
                        used as a blog, but I have to think about what to write first */}
          <li className="nav-item">
            <a href="/Resume.pdf" className="nav-link" target="_blank">
              Resume
            </a>
          </li>
        </ul>
        <hr className="header-break" />
      </header>
    </>
  );
};
