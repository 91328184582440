import React from 'react';
import Smoothie from '../assets/myImages/Smoothie.webp';
import RobotTesting from '../assets/myImages/RobotTesting.webp';
function LifeStorySkills() {
  return (
    <>
      <div className="life-story" data-aos="fade-up">
        <p className="firstSpark">
          {' '}
          <span className="numbering">1.</span>Current Endeavors & Expertise
        </p>
        <div className="lifeStory-innerContent">
          <div className="lifeStory-content">
            <p className="lifeStory-containerText">
              Transitioning from academic pursuits, I continually explore and experiment, with many of{' '}
              <a className="animated-link" href="https://github.com/Ahelsamahy?tab=repositories" target="_blank" rel="noreferrer">
                my projects
              </a>{' '}reflecting personal interests and hobbies, a testament to{' '}
              <span data-toggle="tooltip" data-placement="top" title=" and I get the feeling of proud dad when I see them">
                my passion
              </span>{' '}for technology.
            </p>
            <p className="lifeStory-containerText">As I approached the conclusion of my academic journey, my focus was driven towards artificial intelligence, particularly leveraging Python. My current role as an AI developer is not merely a career choice; it's a manifestation of my genuine interest, independent of academic obligations.</p>

            <p className="lifeStory-containerText">
              In my free time, I've dedicated significant time to Python automation and{' '}
              <span data-toggle="tooltip" data-placement="top" title="This site is an example">
                web development
              </span>{' '}, ensuring that I stay up-to-date with the latest technological advancements. My commitment to excellence is mirrored in my {' '}
              <span data-toggle="tooltip" data-placement="top" title="I graduated the first on class">
                academic performance
              </span>, consistently maintaining a commendable GPA.
            </p>

            <p className="lifeStory-containerText">In terms of technical proficiencies, I'm well-versed in:</p>
            <ul className="learntTech">
              <li>Python</li>
              <li>JavaScript</li>
              <li>ReactJS</li>
              <li>ExpressJS</li>
              <li>SQL</li>
              <li>CSS & HTML</li>
              <li>Java</li>
              <li>C#</li>
              <li>C++</li>
              <li>PHP</li>
            </ul>
          </div>
          <img className="lifeStoryPersonnalImage" src={Smoothie} title="maybe it is spring" alt="Drinking smoothie with a leafy shirt"></img>
        </div>
      </div>

      <div className="life-story" data-aos="fade-up">
        <p className="firstSpark">
          <span className="numbering">2.</span>Robotic Innovations
        </p>
        <div className="lifeStory-innerContent">
          <div className="lifeStory-content">
            <p className="lifeStory-containerText">
              In my current capacity as an AI developer at{' '}
              <a className="animated-link" href="https://voiar.ai/" target="_blank" rel="noreferrer">
                VOIAR
              </a>, I work on the development and enhancement of an advanced forest robot. This machine is designed to assist farmers by autonomously transporting goods, optimising their daily operations.
            </p>
            <p className="lifeStory-containerText">
              Key Project Highlights:
              <ul className="lifeStory_RoboticKeyProject">
                <li>
                  <strong>Tech Stack</strong>: My work heavily depends on Python and Javascript, combined with tools like Balenacloud for seamless fleet management.
                </li>
                <li>
                  <strong>Transitioning to a Mobile-Based Control Interface</strong>: I've developed a mobile-based interface, introducing a new era of user interaction. This interface is a ground-up creation, designed to be the standard mode of operation. It improves the robot's adaptability and the user experience. Additionally, this development involved reverse-engineering the existing codebase, ensuring seamless integration with the new mobile operation system.
                </li>
                <li>
                  <strong>Robot communication</strong>: A key aspect of my work involves developing advanced communication systems between robots. Utilising ZMQ, I engineered a socket communication system that operates at a low level, optimising CPU usage for greater efficiency. This system enables rapid data exchange between robots, with transmission speeds averaging 0.0043 seconds.
                </li>
              </ul>
            </p>
          </div>
          <img className="lifeStoryPersonnalImage" src={RobotTesting} title="maybe it is spring" alt="Testing new release of the mobile controller on 5Earl robot"></img>
        </div>

        <a href="/Resume.pdf" className="animated-link" id='lifeStory-resumeLink' target="_blank">
          My Resume
        </a>

      </div>
    </>
  );
}
export default LifeStorySkills;
