import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { thesisMediaPath, changePageTitle } from '../functions';
import SEO from './SEO.jsx';
import '../styles/11_thesis.css';

export function ThesisBody() {
	const [indexOfViewed, setIndexOfViewed] = useState(0);

	useEffect(() => {
		changePageTitle('Survive Line | AhmedMahfouz.me');
		<SEO Title={'SurviveLine: Master the Waves with AI!'} description={"Dive into the mesmerizing world of Survive Line, a cutting-edge game powered by Pygame, where a ball navigates the treacherous terrain of dynamic waves. Experience the stunning interplay of amplitude and speed, with waves that change and adapt based on their proximity to one another. But this is no ordinary ball - it's powered by the latest in Artificial Intelligence, trained using the renowned N.E.A.T algorithm."} name={'Survive Line'} type={'AI-Driven Endurance Arcade Game'} />;
	}, []);

	useEffect(() => {
		/**
		 * This function calculates the width of every div defined with className "thesisMainContainer"
		 * measure the distance between the top view and the bottom view of the screen and how far is it from the top or the bottom of each div
		 * Move the dot to the corresponding div that can be in it
		 */
		const handleScroll = () => {
			const topOfScreen = window.scrollY;
			const viewportHeight = window.innerHeight;
			const mainContainer = document.querySelectorAll('.thesisMainContainer');
			let elementCoord = [];
			//the percentage of view that can be seen then change the dot
			const whenToShow = 30;

			for (let i = 0; i < mainContainer.length; i++) {
				//a 2D array to store distance to the top and bottom of each div the dot should go to
				elementCoord.push([mainContainer[i].offsetTop, mainContainer[i].offsetTop + mainContainer[i].offsetHeight, 0]);
				const elementHeight = mainContainer[i].offsetHeight;
				const elementOffsetMid = elementCoord[i][0] + elementHeight / 2; //Get the center of div
				const distanceTop = Math.round(topOfScreen + viewportHeight - elementOffsetMid);
				const calcUp = Math.round(distanceTop / (elementHeight / 200));
				const percentageUp = Math.min(100, Math.max(0, calcUp));
				elementCoord[i][2] = percentageUp;
				//if function to change the dot when the user is appropriate amount of view
				if (elementCoord[i][2] > whenToShow && mainContainer[indexOfViewed].offsetParent !== null) {
					setIndexOfViewed(i);
					document.getElementById('dotNavbar').style.zIndex = '10';
				}
			}
			//Change the white dot visibility
			const dotNavhref = document.querySelectorAll('#dotNavbar a');
			dotNavhref.forEach((el) => el.classList.remove('active'));
			if (dotNavhref[indexOfViewed]) {
				dotNavhref[indexOfViewed].className = 'active';
			}
		};
		// To always run the function when action scroll is working
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [indexOfViewed]);

	return (
		<>
			<nav id="dotNavbar">
				<ul>
					<li>
						<HashLink className="nav-link" to={'/thesis#gameHowTo'}></HashLink>
					</li>
					<li>
						<HashLink className="nav-link" to={'/thesis#gameCodeFeature'}></HashLink>
					</li>
					<li>
						<HashLink className="nav-link" to={'/thesis#gameAISolving'}></HashLink>
					</li>
					<li>
						<HashLink className="nav-link" to={'/thesis#gameBehindTheScenes'}></HashLink>
					</li>
					<li>
						<HashLink className="nav-link" to={'/thesis#gameDownload'}></HashLink>
					</li>
				</ul>
			</nav>
			<div id="Game">
				{/* NAME CONTENT */}
				<div id="nameCont">
					<p className="name" data-aos="fade-up" data-aos-delay="2000">
						Survive Line
					</p>
					<p className="nameDescription" data-aos="fade-up" data-aos-delay="1000">
						Can you survive without hitting?
					</p>
					<div className="gameFootage">
						<video data-aos="fade-up" data-aos-delay="2000" className="gameTopView" preload="auto" autoPlay muted loop="True">
							<source src={thesisMediaPath('cropedNormalFootage.webm')} type="video/webm" />
							<p>A crispy 120fps footage the game</p>
						</video>
					</div>
				</div>

				{/* GAME RULES*/}
				<div id="gameHowTo" className="thesisMainContainer">
					<p className="Header">What are the rules?📑</p>
					<div className="ThesisInnerContent">
						<div className="ThesisContent">
							<div className="floating">
								{' '}
								<p>Simple rules to survive, use simple controls to avoid the wave and not to hit it. Keep in mind the game gets harder with the more you play:</p>
								<ul className="floating">
									<li>The space between wave decreases.</li>
									<li>The amplitude of the wave is always changing, so there is no way for you to cheat in it😉.</li>
									<li>The game goes faster until double of when it started time.</li>
								</ul>
							</div>
						</div>
						<video className="thesisMedia normalFootage" preload="auto" autoPlay muted loop="True">
							<source src={thesisMediaPath('normalFootage.webm')} type="video/webm" />
						</video>
					</div>
				</div>

				{/* WHY CHOOSING PYTHON */}
				<div id="gameCodeFeature" className="thesisMainContainer">
					<p className="Header">Code Feature</p>
					<div className="ThesisInnerContent">
						<img className="thesisMedia codeSnippetDiary" src={thesisMediaPath('codeSnippet.png')} alt="Parts from code"></img>
						<div className="ThesisContent">
							<p>The game is totally written in python 🐍 from ground to up due to its flexibility, scalability, and extensibility. That made it easy to use programming language's basic principles, such as encapsulation.</p>
							<p> Encapsulation allowed me to create modular, reusable pieces of code that could be easily incorporated into the game also to maintain a consistent structure and organization throughout the code, making it easier to debug and maintain.</p>
						</div>
					</div>
				</div>

				<div id="gameAISolving" className="thesisMainContainer">
					<p className="Header">Not enough?</p>
					<div className="ThesisInnerContent">
						<video className="thesisMedia AIPlay" preload="auto" autoPlay muted loop="True">
							<source src={thesisMediaPath('AI(12.28).webm')} type="video/webm" />
							<p>A crispy 120fps footage of AI playing the game</p>
						</video>
						<div className="ThesisContent">
							<p className="thesisInnerHeader">How about adding AI to beat the game?🤔</p>I used N.E.A.T algorithm to make AI that can beat the game without me touching the keyboard at all
							<ul>
								<li>The yellow lines are the vision for the ball. It can see more 20px in front so it acts as a futuristic vision for it.</li>
								<li>
									A small <span>grey box</span> show around the ball, that is the collision box. The game will stop if this box hits the wave.
								</li>
								<li>the particles behind the ball are removed to save CPU sources.</li>
								<li>A threshold of 100 point is made, so all the genomes can have equal time to train.</li>
								<li>Highest score ever reached was more than 30 min of playing 🤩.</li>
							</ul>
							<p className="youTubeNote">
								You can view the full training session on{' '}
								<a className="inlineLink" href="https://www.youtube.com/watch?v=ZYRZjhS5VgA">
									Youtube
								</a>
								.
							</p>
							<br></br>
							<p className="note">*when the game freezes for a sec, it isn't a problem, it is just the algorithm calculating the next step.</p>
						</div>
					</div>
				</div>

				{/* MARKDOWN WITH LATEX */}
				<div id="gameBehindTheScenes" className="thesisMainContainer">
					<p className="Header">Curious for more</p>
					<div className="ThesisInnerContent">
						<img className="thesisMedia markDownDiary" src={thesisMediaPath('markdown log.png')} alt=""></img>
						<div className="ThesisContent">
							<p>
								I wrote the project progress as I was walking forward in it. That was helpful while writing my actual thesis in{' '}
								<span className="latex">
									L<sup>A</sup>T<sub>E</sub>X
								</span>{' '}
								later.
							</p>
							<p>
								You can view the diary that I wrote during the development from{' '}
								<Link to="/thesistimeline" className="inlineLink">
									here
								</Link>
							</p>
						</div>
					</div>
				</div>

				<div id="gameDownload" className="thesisMainContainer">
					<p className="Header">Want to try yourself🕹</p>
					<div className="ThesisInnerContent">
						<div className="ThesisContent ">
							<p>
								{' '}
								You can now download "Survive Line" and train the AI, tweak in it and see what obstacles it will avoid from the{' '}
								<a className="inlineLink" href="https://github.com/Ahelsamahy/Survive-Line" target="_blank" rel="noreferrer">
									GitHub
								</a>{' '}
								repository.
							</p>
							<p>
								All of this had to be written down in a technical and scientific way. The{' '}
								<a title="Hefty 30+ pages" className="inlineLink" href={thesisMediaPath('LaTeX_Thesis.pdf')} target="_blank" rel="noreferrer">
									<span className="latex">
										L<sup>A</sup>T<sub>E</sub>X
									</span>
								</a>{' '}
								version can be viewed online.
							</p>
						</div>
						<a href={thesisMediaPath('SurviveLine.zip')}>
							<img className="thesisMedia gameLogo" src={thesisMediaPath('Gamelogo.webp')} alt=""></img>
						</a>
					</div>
				</div>
			</div>
		</>
	);
}
