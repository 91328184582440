import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Markup } from 'interweave';
import '../styles/23.2_storyProject.css';
import { changePageTitle } from '../functions';
import SEO from './SEO.jsx';
import ConfigCredentials from '../config.jsx';
import Prism from 'prismjs';
let config = ConfigCredentials();

export function StoryProject() {
  const [data, setData] = useState([]);
  const { projectID } = useParams(); // This should match the param name in your route path

  useEffect(() => {
    Prism.highlightAll();
  }, [data.Content]);

  useEffect(() => {
    // Use projectName to fetch the correct project data
    fetch(`${config.backendURL}/get/Project/${projectID}`) // Make sure to update your backend route to handle project names
      .then((response) => response.json())
      .then((data) => {
        setData(data[0]);
        changePageTitle(`${data[0].Title} | AhmedMahfouz.me`);
        Prism.highlightAll();
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [projectID]); // Depend on projectID
  return (
    <>
      <SEO Title={data.MetaTitle} description={data.MetaDescription} name={data.Title} type={`${data.ProLanguage} Project`} />
      <div className="container">
        <div className="contentDivPost">
          <p className="titlePost">{data.Title}</p>
          <p className="programmedLang-title">Made with: {data.ProLanguage}</p>
        </div>
        {/* show the whole post  */}
        <div id="post">
          <Markup content={data.Content} />
        </div>
      </div>
    </>
  );
}
