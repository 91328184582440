import React, { useEffect, useState } from 'react';
import ProjectCard from './projectCard.jsx';
import '../styles/cards.css';
import ConfigCredentials from '../config.jsx';

let config = ConfigCredentials();

export function AllProjects() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`${config.backendURL}/get/allprojects/`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setData(data))
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error);
      });
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="container ForAllProjectsContainer">
        <ProjectCard data={data} showImage={false} />
      </div>
    </>
  );
}
