import React, { useEffect, useState } from 'react';

import '../styles/22_lifeStory.css';
import LifeStorySkills from './22_lifeStorySkills.jsx';
import LifeStoryPersonnal from './22_lifeStoryPersonnal.jsx';
import AOS from 'aos';

export function LifeStory() {
	const [selectedSection, setSelectedSection] = useState(1);

	useEffect(() => {
		AOS.refresh();
	}, [selectedSection]);

	return (
		<div className="container all-of-life ">
			<div className="section-titles">
				<span data-aos="fade-right" className={`animated-link ${selectedSection === 1 ? 'active' : ''}`} onClick={() => setSelectedSection(1)}>
					Professional Milestones
				</span>
				<span data-aos="fade-left" className={`animated-link ${selectedSection === 2 ? 'active' : ''}`} onClick={() => setSelectedSection(2)}>
					Personal Journey
				</span>
			</div>

			{selectedSection === 1 && (
				<div data-aos="fade-right">
					<LifeStorySkills />
				</div>
			)}
			{selectedSection === 2 && (
				<div data-aos="fade-left">
					<LifeStoryPersonnal />
				</div>
			)}
		</div>
	);
}
