export function myImagePath(mediaName) {
	let mediaURL = require(`./assets/myImages/${mediaName}`);
	return mediaURL;
}
export function storyCoverPath(mediaName) {
	let mediaURL = require(`./assets/coverStory/${mediaName}`);
	return mediaURL;
}

export function storyMediaPath(mediaName) {
	let mediaURL = require(`./assets/inStory/${mediaName}`);
	return mediaURL;
}

export function thesisMediaPath(mediaName) {
	let mediaURL = require(`./assets/Thesis/${mediaName}`);
	return mediaURL;
}

export function changePageTitle(newTitle) {
	document.title = newTitle;
}

export function getFourthPathSegment(url) {
	const parts = url.split('/');
	return parts[3] || ''; // Return the part after the third slash or an empty string if not present
}

/**
 * Change the colour of the header to match the accent colour of the page
 */
export function changeHeaderColour() {
  // Check if window width is less than or equal to 490 pixels
  if (window.innerWidth <= 490) {
      // Set the .indexHeader background to transparent and reset body background
      document.getElementsByClassName('indexHeader')[0].style.background = 'transparent';
      document.body.style.backgroundColor = ''; // Remove the inline style
      return; // Exit the function
  }

  var a = window.location.href.replace(window.location.protocol, '');
  if (a.includes('thesis')) {
      document.getElementsByClassName('indexHeader')[0].style.background = 'black';
      document.body.style.backgroundColor = 'black';
      console.log('Changing the colour for header and background');
  } else {
      document.getElementsByClassName('indexHeader')[0].style.background = '#121212';
      document.body.style.backgroundColor = '#121212';
  }
}
