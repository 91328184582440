import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.css';

import { Header } from './JSX/10_header';
import { Body } from './JSX/20_body.jsx';
import { Footer } from './JSX/30_footer.jsx';
import { ThesisBody } from "./JSX/11_thesis.jsx";
import { ThesisTimeline } from "./JSX/11.1_thesisTimeline.jsx";
import { AllProjects } from "./JSX/23.1_allProjects.jsx";
import { StoryProject } from "./JSX/23.2_storyProject.jsx";
import './styles/query.css'; //Style for media query. MUST BE THE LAST ONE
import { changeHeaderColour, getFourthPathSegment } from "./functions.jsx"
import { HelmetProvider } from 'react-helmet-async';
import Error404 from './JSX/Error404.jsx';

import './styles/app.css';
import './styles/10_header.css'; // Style for header only
import './styles/loginStyle.css'; // Style for login page
import './styles/animation.css'; //Style for animations
import './styles/prism-gruvbox-dark.css';
import 'prismjs/components/prism-python'; // For Python highlighting

import SmoothScroll from './JSX/smoothScrolling';
import { AllBlackIntro } from './JSX/welcomeAnimation';


export function App() {
  const location = useLocation();
  const handleResize = () => {
    changeHeaderColour();
  };
  useEffect(() => {
    AOS.init();
  }, [])

  useEffect(() => {
    // Call the function initially due to a change in location
    changeHeaderColour();

    // Add the event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [location]);  // Dependency on location ensures this useEffect reruns every time location changes

  // State to control visibility of AllBlackIntro
  const [showWelcome, setShowWelcome] = useState(false);

  useEffect(() => {
    const currentPath = getFourthPathSegment(window.location.href);
    if (currentPath.length === 0) {
      console.log("home page")
      setShowWelcome(true); // Show the component on home page
    } else {
      setShowWelcome(false); // Hide the component on other pages
    }
  }, [location]);

  return (
    <>
      <HelmetProvider>
        {/* Each part with different route will have a SEO helmet component for itself
        The one with the last initialized <SEO/> will be the one used */}
        <AllBlackIntro show={showWelcome} />
        <div id='page-container'>
          <div id='content-wrap'>
            <Header />
            <SmoothScroll>
              <Routes>
                <Route path='/' element={<Body />} />
                <Route path='/Home' element={<Body />} />
                <Route path='/thesis' element={<ThesisBody />} />
                <Route path='/thesistimeline' element={<ThesisTimeline />} />
                <Route path='/allProjects' element={<AllProjects />} />
                <Route path="/storyProject/:projectID" element={<StoryProject />} />

                <Route path='*' element={<Error404 />} />
              </Routes>
            </SmoothScroll>
          </div>
          <Footer />
        </div>
      </HelmetProvider>
    </>
  );
}