import React, { useEffect } from 'react';
import SEO from './SEO.jsx';
import { changePageTitle } from '../functions.jsx';
import IntroSection from './21_introSection.jsx';
import { LifeStory } from './22_lifeStory.jsx';
import { Top3Projects } from './23_top3Projects.jsx';
import { ContactForm } from './24_contactMe.jsx';

export function Body() {
	useEffect(() => {
		changePageTitle(`Ahmed Mahfouz`);
	});

	return (
		<>
			<SEO Title="Pixels and Programming: The Portfolio of Ahmed Mahfouz"
       description="Greetings and salutations! I'm Ahmed Mahfouz, and this is where the magic of code comes to life. Step into a realm where imagination meets technology as I guide you through my projects, delve into my academic journey, and share the pivotal moments that shaped me into the programmer I am today."
        name="Portfolio of Ahmed Mahfouz"
         type="Portfolio web site"
          />

			<IntroSection />

			<div id="aboutSection">
				<hr className="page-break" data-aos="zoom-out"></hr>
				<LifeStory />
			</div>

			<hr className="page-break" data-aos="zoom-out"></hr>
			<Top3Projects />

			<hr className="page-break" data-aos="zoom-out"></hr>
			<ContactForm />
		</>
	);
}
